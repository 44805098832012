import React from "react";
import { Link } from 'gatsby';
import * as ROUTES from '../constants/routes';

import Layout from "../App/components/layout";
import SEO from "../App/components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="" />
    <div className="wrapper">
    <h1>404 - How’d that happen?</h1>
        <p>You somehow reached our 404 page. Let us get you back on track:</p>
        <ol>
            <li>Try returning to the <Link href={ROUTES.INDEX}>homepage</Link></li>
            <li>Hit the back button</li>
            <li>Sign out of your account and begin again</li>
        </ol>
        <p>If your problem persists, please <a href="mailto:info@ceca-mcp.com" target="_blank" rel="noopener noreferrer">contact us.</a></p>
    </div>
  </Layout>
)

export default NotFoundPage
